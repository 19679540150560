import { connect } from 'react-redux';

import type { TFunction } from 'i18next';
import type { ConnectedProps } from 'react-redux';

import { switchDraftTabIndex, updateTicketTabState } from '../actions/draftActions';
import { addMitelCallRequest, addOCCallRequest, addRingCallRequest } from '../actions/phoneActions';
import {
  addTagToContent,
  onCommentSubmit,
  sendEntityEvent,
  updateChatTypingStatusToCustomer,
  updateGiosgWritingStatus,
  updateTicket,
  uploadFile
} from '../actions/ticketsActions';
import ReplyChannelTabBar from '../Components/ReplyMethods/ReplyChannelTabBar';
import { setUserChatAnchorStatus } from 'src/actions/userActions';
import { ticketStopWorkingOn } from 'src/actions/workStatusActions';
import { selectActiveTicket } from 'src/selectors/rootStateSelectors';
import { getCaseTabIndexList } from 'src/Utilities/getCaseTabIndexLIst';

import type { Draft } from 'src/reducers/draftsReducer';
import type { State } from 'src/types/initialState';

export interface ReplyChannelTabBarProps extends ReplyChannelTabBarReduxProps {
  t: TFunction;
}

const connector = connect(
  (state: State) => {
    const ticket = selectActiveTicket(state)!;

    return {
      ticket,
      tags: state.tags,
      userData: state.userData,
      suggestions: state.suggestions,
      ticketTypes: state.ticketTypes,
      users: state.usersList.usersList,
      mobileMode: state.mobileReducer.mobileMode,
      templates: state.templates.responseTemplates,
      drafts: state.drafts[ticket?.id ?? ''] as Draft | undefined,
      replyTabIndexList: getCaseTabIndexList(state.userData.channels),
      user: state.usersList.usersList.find((user) => user.UID === state.userData.UID)!
    };
  },
  {
    updateTicketTabState,
    onSubmit: onCommentSubmit,
    setChatAnchor: setUserChatAnchorStatus,
    addTag: addTagToContent,
    uploadFile,
    sendEntityEvent,
    updateGiosgWritingStatus,
    updateChatTypingStatusToCustomer,
    switchDraftTab: switchDraftTabIndex,
    addRingCallRequest,
    addOCCallRequest,
    addMitelCallRequest,
    ticketStopWorkingOn,
    updateTicket
  }
);

export type ReplyChannelTabBarReduxProps = ConnectedProps<typeof connector>;

export default connector(ReplyChannelTabBar);
