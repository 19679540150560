import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Segment } from 'semantic-ui-react';

import type { FC } from 'react';

import RemoveButton from '../RemoveButton';

import type { OnInputChange, ParameterOption } from 'src/types/Campaign';

interface MatchArrayOptionProps {
  optionIndex: number;
  option: ParameterOption<'matchArray'>;

  onOptionRemove: (optionIndex: number) => void;
  onOptionUpdate: (optionIndex: number, option: ParameterOption<'matchArray'>) => void;
}

const MatchArrayOption: FC<MatchArrayOptionProps> = ({ option, optionIndex, onOptionRemove, onOptionUpdate }) => {
  const { t } = useTranslation();

  const onOutputValueUpdate = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, { ...option, outputValue: value });
    },
    [option, optionIndex]
  );
  const onTargetValueUpdate = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, { ...option, targetValue: value });
    },
    [option, optionIndex]
  );
  const onTargetVariableUpdate = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, { ...option, targetVariables: value });
    },
    [option, optionIndex]
  );
  const removeOption = useCallback(() => {
    onOptionRemove(optionIndex);
  }, [option, optionIndex]);

  return (
    <Segment>
      <Form.Group>
        <RemoveButton description={t('generic.parameters.remove_option')} remove={removeOption} small />
        <Form.Input
          required
          width={6}
          value={option.targetValue}
          error={!option.targetValue}
          label={t('generic.parameters.output_value')}
          onChange={onTargetValueUpdate}
        />
        <Form.Input
          required
          width={6}
          value={option.outputValue}
          error={!option.outputValue}
          label={t('generic.parameters.target_value')}
          onChange={onOutputValueUpdate}
        />
        <Form.Input
          required
          width={6}
          value={option.targetVariables}
          error={!option.targetVariables}
          label={t('generic.parameters.target_variable')}
          onChange={onTargetVariableUpdate}
        />
      </Form.Group>
    </Segment>
  );
};

export default React.memo(MatchArrayOption);
