import iziToast from 'izitoast';
import React, { useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, Grid, Icon, Modal } from 'semantic-ui-react';

import ArchialCustomerCard from './ArchivalCustomerCard';
import ArchivalFiles from './ArchivalFiles';
import ArchivalSettings from './ArchivalSettings';
import ArchivalTemplates from './ArchivalTemplates';
import { fetchTemplates } from 'src/actions/templateActions';
import ArchivalApi from 'src/api/ArchivalApi';
import FeatureFlags from 'src/api/FeatureFlags';
import { useModal } from 'src/Components/Modal/ModalContext';
import { selectActiveTicket } from 'src/selectors/rootStateSelectors';
import { useAppThunkDispatch } from 'src/store';

import type { ArchivalAction, ArchivalState } from './types';
import type { State } from 'src/types/initialState';
import type { Template, TemplateArchivalJson } from 'src/types/Template';

import './Archival.css';

function reducer(state: ArchivalState, action: ArchivalAction): ArchivalState {
  switch (action.type) {
    case 'SET_SELECTED_FILES':
      return { ...state, selectedFiles: action.payload };
    case 'SET_SELECTED_TEMPLATE':
      return { ...state, selectedTemplate: action.payload };
    case 'SET_INCLUDE_COMMENTS':
      return { ...state, includeComments: action.payload };
    case 'SET_ADDITIONAL_FIELDS':
      return { ...state, additionalFields: action.payload };
    case 'SET_QUEUE':
      return { ...state, selectedQueue: action.payload };
    case 'SET_ARCHIVE_JOB':
      return { ...state, isArchiveJob: action.payload };
    case 'SET_SUBMITTING':
      return { ...state, isSubmitting: action.payload };
    case 'SET_MAIN_FILE_TYPE':
      return { ...state, mainFileType: action.payload };
    default:
      return state;
  }
}

const ArchivalModal = () => {
  const { t } = useTranslation();
  const dispatch = useAppThunkDispatch();
  const { closeModal } = useModal();
  const isTemplateSelectionEnabled = !FeatureFlags.isFlagOn('ARCHIVAL_SINGLE_TEMPLATE_ENABLED');

  const ticket = useSelector(selectActiveTicket);
  const templates = useSelector((state: State) =>
    (state.templates.templates as Template<TemplateArchivalJson>[]).filter(
      (template) => template.type === 'archivalTemplate'
    )
  );

  const getInitialState = (): ArchivalState => ({
    selectedFiles: [],
    includeComments: false,
    additionalFields: {},
    isArchiveJob: false,
    isSubmitting: false,
    selectedQueue: 0,
    ...(!isTemplateSelectionEnabled && { selectedTemplate: templates[0].name })
  });
  const [state, dispatchState] = useReducer(reducer, getInitialState());

  const onSubmit = async () => {
    if (!ticket) {
      return;
    }

    try {
      dispatchState({ type: 'SET_SUBMITTING', payload: true });
      await ArchivalApi.archiveTicket({
        ticketId: parseInt(ticket.id.substring(3)),
        selectedTemplate: state.selectedTemplate as string,
        includeComments: state.includeComments,
        additionalFields: state.additionalFields,
        selectedAttachments: state.selectedFiles,
        selectedQueue: state.selectedQueue,
        archiveJob: state.isArchiveJob,
        arriveTimestamp:
          ticket.comments.find(
            (comment) => comment.type !== 'automatic' && comment.direction === 'in' && comment.content !== null
          )?.created || ticket.created,
        ...(state.mainFileType && { mainFileType: state.mainFileType })
      });

      iziToast.success({
        message: t('top_bar.advanced_actions.archive.toasts.archive_started'),
        timeout: 5000
      });
      closeModal();
    } catch (error) {
      iziToast.error({
        message: t('top_bar.advanced_actions.archive.toasts.archive_failed'),
        icon: 'ban',
        timeout: 7500,
        position: 'bottomRight'
      });
    } finally {
      dispatchState({ type: 'SET_SUBMITTING', payload: false });
    }
  };

  useEffect(() => {
    dispatch(fetchTemplates());
  }, []);

  if (!ticket) {
    return null;
  }

  return (
    <>
      <Modal.Header>{t('top_bar.advanced_actions.archive.labels.archive_title')}</Modal.Header>

      <Modal.Content>
        <Grid>
          <Grid.Row>
            <Grid.Column width={7}>
              <ArchivalSettings localState={state} dispatchState={dispatchState} />

              <ArchialCustomerCard
                archivalTemplate={templates.find((template) => template.name === state.selectedTemplate)}
                localState={state}
                dispatchState={dispatchState}
              />
            </Grid.Column>

            <Grid.Column width={9}>
              {isTemplateSelectionEnabled && (
                <ArchivalTemplates templates={templates} localState={state} dispatchState={dispatchState} />
              )}
              <ArchivalFiles files={ticket.attachments} localState={state} dispatchState={dispatchState} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal.Content>

      <Modal.Actions>
        <Button
          content={t('GENERAL_CLOSE')}
          negative
          icon="remove"
          labelPosition="right"
          onClick={() => closeModal()}
        />
        <Button
          content={t('top_bar.advanced_actions.archive.buttons.archive')}
          positive
          icon={state.isSubmitting ? <Icon name="circle notch" loading /> : 'send'}
          labelPosition="right"
          onClick={() => onSubmit()}
          disabled={
            state.isSubmitting ||
            !state.selectedTemplate ||
            !!Object.keys(state.additionalFields).find((fieldName) => !state.additionalFields[fieldName])
          }
        />
      </Modal.Actions>
    </>
  );
};

export default ArchivalModal;
