import React, { useCallback } from 'react';

import type { FC } from 'react';

import AddOptionButton from '../AddOptionButton';
import DateOption from './DateOption';

import type {
  DateParam,
  DateParameterMode,
  Parameter,
  ParameterOption,
  TypedParameterCallbacks
} from 'src/types/Campaign';

interface DateParameterProps extends TypedParameterCallbacks {
  index: number;
  parameter: Parameter;
  dateParametersMode?: Record<number, DateParameterMode>;

  onModeUpdate(args: { optionIndex: number; mode: DateParameterMode }): void;
}

const DateParameter: FC<DateParameterProps> = ({
  parameter,
  index,
  dateParametersMode,
  onAddOption: addOption,
  onOptionUpdate: updateOption,
  onOptionRemove: removeOption,
  onModeUpdate: updateMode
}) => {
  const onAddOption = useCallback(() => {
    addOption('date');
  }, []);
  const onOptionUpdate = useCallback((optionIndex: number, option: ParameterOption<'date'>) => {
    updateOption({ optionIndex, type: 'date', option });
  }, []);
  const onModeUpdate = useCallback((optionIndex: number, mode: DateParameterMode) => {
    updateMode({ optionIndex, mode });
  }, []);
  const onOptionRemove = useCallback(
    (optionIndex: number) => {
      removeOption({ optionIndex, type: 'date' });
    },
    [parameter, index]
  );

  const data = (parameter.data ?? []) as DateParam[];

  return (
    <>
      {data?.map?.((option, optionIndex) => (
        <DateOption
          key={`campaign-date-parameter-option-${index}-${optionIndex}`}
          option={option}
          optionIndex={optionIndex}
          onModeUpdate={onModeUpdate}
          onOptionUpdate={onOptionUpdate}
          onOptionRemove={onOptionRemove}
          mode={dateParametersMode?.[optionIndex]}
        />
      ))}

      <AddOptionButton onAddOption={onAddOption} />
    </>
  );
};

export default React.memo(DateParameter);
