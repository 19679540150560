import React, { useCallback } from 'react';

import type { FC } from 'react';

import AddOptionButton from '../AddOptionButton';
import PushToArrayOption from './PushToArrayOption';

import type { Parameter, ParameterOption, TypedParameterCallbacks } from 'src/types/Campaign';

interface PushToArrayProps extends TypedParameterCallbacks {
  index: number;
  parameter: Parameter;
}

const PushToArrayParameter: FC<PushToArrayProps> = ({
  parameter,
  index,
  onAddOption: addOption,
  onOptionUpdate: updateOption,
  onOptionRemove: removeOption
}) => {
  const onAddOption = useCallback(() => {
    addOption('pushToArray');
  }, []);
  const onUpdateOption = useCallback((optionIndex: number, option: ParameterOption<'pushToArray'>) => {
    updateOption({ type: 'pushToArray', optionIndex, option });
  }, []);
  const onRemoveOption = useCallback((optionIndex: number) => {
    removeOption({ type: 'pushToArray', optionIndex });
  }, []);

  const options = (parameter.data?.targetVariables ?? []) as string[];

  return (
    <>
      {options.map?.((option, optionIndex) => (
        <PushToArrayOption
          key={`campaign-push-array-option-${index}-${optionIndex}`}
          option={option}
          optionIndex={optionIndex}
          onOptionRemove={onRemoveOption}
          onOptionUpdate={onUpdateOption}
        />
      ))}

      <AddOptionButton onAddOption={onAddOption} />
    </>
  );
};

export default React.memo(PushToArrayParameter);
