import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Segment } from 'semantic-ui-react';

import type { FC } from 'react';

import RemoveButton from '../RemoveButton';

import type { OnInputChange, ParameterOption } from 'src/types/Campaign';

interface PushToArrayOptionProps {
  optionIndex: number;
  option: ParameterOption<'pushToArray'>;

  onOptionRemove: (optionIndex: number) => void;
  onOptionUpdate: (optionIndex: number, option: ParameterOption<'pushToArray'>) => void;
}

const PushToArrayOption: FC<PushToArrayOptionProps> = ({ option, optionIndex, onOptionRemove, onOptionUpdate }) => {
  const { t } = useTranslation();

  const onTargetVariableChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, value);
    },
    [option, optionIndex]
  );
  const removeOption = useCallback(() => {
    onOptionRemove(optionIndex);
  }, [option, optionIndex]);

  return (
    <Segment>
      <RemoveButton description={t('generic.parameters.remove_option')} remove={removeOption} small />
      <Form.Input
        required
        value={option}
        error={!option}
        label={t('generic.parameters.target_variable')}
        placeholder={t('generic.parameters.variable_name')}
        onChange={onTargetVariableChange}
      />
    </Segment>
  );
};

export default React.memo(PushToArrayOption);
