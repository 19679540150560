import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import type { FC } from 'react';

import AddOptionButton from '../AddOptionButton';
import MatchArrayOption from './MatchArrayOption';

import type { OnInputChange, Parameter, ParameterOption, TypedParameterCallbacks } from 'src/types/Campaign';

interface MatchArrayParameterProps extends TypedParameterCallbacks {
  index: number;
  parameter: Parameter;

  changeParameter(args: { prop: keyof Parameter; value: any; index: number }): void;
}

const MatchArrayParameter: FC<MatchArrayParameterProps> = ({
  parameter,
  index,
  onAddOption: addOption,
  onOptionUpdate: updateOption,
  onOptionRemove: removeOption,
  changeParameter
}) => {
  const { t } = useTranslation();

  const onDefaultOutputChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      changeParameter({ prop: 'data', value: { ...parameter.data, defaultOutput: value }, index });
    },
    [parameter.data, index]
  );
  const onAddOption = useCallback(() => {
    addOption('matchArray');
  }, []);
  const onOptionUpdate = useCallback((optionIndex: number, option: ParameterOption<'matchArray'>) => {
    updateOption({ optionIndex, type: 'matchArray', option });
  }, []);
  const onOptionRemove = useCallback((optionIndex: number) => {
    removeOption({ optionIndex, type: 'matchArray' });
  }, []);

  const options = (parameter.data?.matchList ?? []) as ParameterOption<'matchArray'>[];

  return (
    <>
      <Form.Input
        label={t('generic.parameters.default_output')}
        value={parameter.data?.defaultOutput}
        onChange={onDefaultOutputChange}
      />
      {options.map((option, optionIndex) => (
        <MatchArrayOption
          key={`campaign-match-array-option-${index}-${optionIndex}`}
          option={option}
          optionIndex={optionIndex}
          onOptionRemove={onOptionRemove}
          onOptionUpdate={onOptionUpdate}
        />
      ))}

      <AddOptionButton onAddOption={onAddOption} />
    </>
  );
};

export default React.memo(MatchArrayParameter);
