import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'semantic-ui-react';

import type { FC } from 'react';

import type { OnDropdownChange, Parameter } from 'src/types/Campaign';

interface ParameterTypeProps {
  parameter: Parameter;
  onTypeChange: OnDropdownChange;
}

const options = [
  { text: 'String', value: 'string' },
  { text: 'Date', value: 'date' },
  { text: 'Enum', value: 'enum' },
  { text: 'MatchArray', value: 'matchArray' },
  { text: 'PushToArray', value: 'pushToArray' }
];

const ParameterType: FC<ParameterTypeProps> = ({ parameter, onTypeChange }) => {
  const { t } = useTranslation();

  return (
    <Form.Field
      fluid
      width={4}
      control={Select}
      label={t('generic.parameters.type')}
      options={options}
      value={parameter.type}
      placeholder={t('generic.parameters.select_type')}
      onChange={onTypeChange}
      required
    />
  );
};

export default React.memo(ParameterType);
