import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import type { FC } from 'react';

import AddOptionButton from '../AddOptionButton';
import EnumOption from './EnumOption';

import type {
  OnInputChange,
  Parameter,
  ParameterOption,
  EnumParameter as TEnumParameter,
  TypedParameterCallbacks
} from 'src/types/Campaign';

interface EnumParameterProps extends TypedParameterCallbacks {
  index: number;
  parameter: Parameter;

  changeParameter(args: { prop: keyof Parameter; value: any; index: number }): void;
}

const EnumParameter: FC<EnumParameterProps> = ({
  parameter,
  index,
  onAddOption: addOption,
  onOptionUpdate: updateOption,
  onOptionRemove: removeOption,
  changeParameter
}) => {
  const { t } = useTranslation();

  const onTargetVariableChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      changeParameter({ prop: 'data', value: { ...parameter.data, targetVariables: value }, index });
    },
    [parameter.data, index]
  );
  const onDefaultOutputChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      changeParameter({ prop: 'data', value: { ...parameter.data, defaultOutput: value }, index });
    },
    [parameter.data, index]
  );
  const onAddOption = useCallback(() => {
    addOption('enum');
  }, []);
  const onOptionUpdate = useCallback((optionIndex: number, option: ParameterOption<'enum'>) => {
    updateOption({ optionIndex, type: 'enum', option });
  }, []);
  const onOptionRemove = useCallback((optionIndex: number) => {
    removeOption({ optionIndex, type: 'enum' });
  }, []);

  const data: TEnumParameter['data'] = parameter.data;
  const options = data?.options ?? [];

  return (
    <>
      <Form.Group widths={'equal'}>
        <Form.Input
          required
          label={t('generic.parameters.target_variable')}
          value={data.targetVariables}
          onChange={onTargetVariableChange}
        />
        <Form.Input
          label={t('generic.parameters.default_output')}
          value={data.defaultOutput}
          onChange={onDefaultOutputChange}
        />
      </Form.Group>
      {options.map((option, optionIndex) => (
        <EnumOption
          key={`campaign-enum-parameter-option-${index}-${optionIndex}`}
          option={option}
          optionIndex={optionIndex}
          onOptionUpdate={onOptionUpdate}
          onOptionRemove={onOptionRemove}
        />
      ))}
      <AddOptionButton onAddOption={onAddOption} />
    </>
  );
};

export default React.memo(EnumParameter);
