import { ChannelType } from '@eeedo/types';
import React from 'react';

import OpenInWindowButton from '../OpenInWindowButton';
import TopBarCopyDropdown from '../TopBarCopyDropdown';
import FeatureFlags from 'src/api/FeatureFlags';
import AdvancedActions from 'src/Components/Case/TopBar/AdvancedActions';
import { selectActiveTicket } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';

import './TopBarButtonsGroup.css';

const TopBarButtonsGroup = () => {
  const task = useAppSelector(selectActiveTicket);
  const showOpenInWindowButton =
    FeatureFlags.isFlagOn('ENABLE_FLOATING_WINDOW') &&
    task &&
    (task.channel === ChannelType.Giosg || task.channel === ChannelType.Chat || task.type === 'infopage');

  return (
    <div className="topBarButtonsGroup">
      {showOpenInWindowButton && <OpenInWindowButton ticket={task} />}

      {task && (
        <TopBarCopyDropdown contentType={task.type} title={task.title} caseId={task.id} taskCreated={task.created} />
      )}

      <AdvancedActions />
    </div>
  );
};

export default TopBarButtonsGroup;
