import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import type { TicketType, WidgetName } from '@eeedo/types';

import WidgetPopupContent from './WidgetPopupContent';
import { getWidgetIcon } from 'src/Components/Case/Widget/widgetsConfig';
import { useWidgetView } from 'src/Components/Case/Widget/WidgetViewContext';
import Button from 'src/Components/generic/Button/Button';
import Popup from 'src/Components/generic/Popup/Popup';
import { getWidgetOrder } from 'src/Components/Utilities/widgets';
import { selectActiveTicket } from 'src/selectors/rootStateSelectors';
import { useAppSelector } from 'src/store';

import type { State } from 'src/types/initialState';
import Scrollbars from 'react-custom-scrollbars';

export const DesktopLayoutWidgetMenu = () => {
  const { selectedWidget, isRightSidebarOpen, isSingleWidgetView, setSelectedWidget, toggleRightSidebar } =
    useWidgetView();
  const currentTask = useAppSelector(selectActiveTicket);
  const ticketTypes = useSelector<State>((state) => state.ticketTypes) as TicketType[];

  const widgets = getWidgetOrder(currentTask, ticketTypes, selectedWidget);

  const handleSelectWidget = useMemo(
    () =>
      ({ displayName, widgetName }: { displayName: string; widgetName: WidgetName }) => {
        if (!isRightSidebarOpen) {
          toggleRightSidebar();
        }

        if (!isSingleWidgetView) {
          const target = document.getElementById(`widget_${widgetName}`);
          if (target) {
            target.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }

        setSelectedWidget(displayName);
      },
    [isRightSidebarOpen, isSingleWidgetView, toggleRightSidebar, setSelectedWidget]
  );

  return (
    <div className={classNames('layout-desktop-widget-side-menu')}>
      <Scrollbars
        autoHide
        renderTrackHorizontal={(props) => <div {...props} className="track-horizontal" />}
        renderThumbHorizontal={(props) => <div {...props} className="thumb-horizontal" />}
        className="scrollbarWrapper"
      >
        <Button
          className="icon-wrapper"
          type="transparent"
          icon={<FontAwesomeIcon icon={isRightSidebarOpen ? faArrowRight : faArrowLeft} />}
          borderless
          onClick={() => toggleRightSidebar()}
        />
        {widgets.map((widget) => (
          <Popup
            key={widget.name + widget.displayName}
            on={'hover'}
            positionFixed
            delay={250}
            trigger={
              <div
                className={classNames('icon-wrapper', { active: isRightSidebarOpen && widget.isActive })}
                onClick={() =>
                  handleSelectWidget({ displayName: widget.displayName || widget.name, widgetName: widget.name })
                }
              >
                {getWidgetIcon(widget.name)}
              </div>
            }
            content={<WidgetPopupContent displayName={widget.displayName} name={widget.name} options={widget.options} />}
            position="left center"
          />
        ))}
      </Scrollbars>
    </div>
  );
};
