import React from 'react';

import type { Tag } from '@eeedo/types';
import type { FC } from 'react';

import * as styles from './TicketListItem.style';
import { Label } from 'src/Components/generic';
import ErrorBoundary from 'src/ErrorBoundary';

interface Props {
  sortedTags: Tag[];
  className?: string;
}

const AllTags: FC<Props> = ({ sortedTags, className, ...props }) => (
  <ErrorBoundary>
    {sortedTags.map((tag: Tag) => {
      const style = styles.allTagsLabel({
        bgColor: (tag?.styles?.bgColor as string) ?? '#e8e8e8',
        fontColor: (tag?.styles?.fontColor as string) ?? 'rgba(0,0,0,.6)'
      });

      return (
        <Label style={style} data-test={tag.name} className={className} key={tag.id} {...props}>
          {tag.name}
        </Label>
      );
    })}
  </ErrorBoundary>
);

export default AllTags;
