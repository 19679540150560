import React from 'react';
import { connect } from 'react-redux';
import { Grid, Header } from 'semantic-ui-react';

import type { ConnectedProps } from 'react-redux';

import TitleTemplates from '../TitleTemplates';
import EIDRelationVerifier from './EIDRelationVerifier';
import TopBarButtonsGroup from './TopBarButtonsGroup/TopBarButtonsGroup';
import TopBarLabelList from './TopBarLabelList/TopBarLabelList';
import { updateTicket } from 'src/actions/ticketsActions';
import FeatureFlags from 'src/api/FeatureFlags';
import TopBarTitleInput from 'src/Components/Case/TopBar/TopBarTitleInput';
import { selectActiveTicket } from 'src/selectors/rootStateSelectors';

import type { ContentTypes } from 'src/types/ContentTypes';
import type { State } from 'src/types/initialState';
import type { ThunkAppDispatch } from 'src/types/store';
import type { Ticket } from 'src/types/Ticket';

import './TopBarGeneralInfo.css';

interface TopBarGeneralInfoProps extends TopBarGeneralInfoHOCProps {
  contentType: ContentTypes;
  refContext?: React.RefObject<HTMLElement>;
}

interface TopBarGeneralInfoState {
  isEditingTitle: boolean;
  taskTitle: string;
  savedTitle: string;
}

class TopBarGeneralInfo extends React.Component<TopBarGeneralInfoProps, TopBarGeneralInfoState> {
  constructor(props: TopBarGeneralInfoProps) {
    super(props);

    const title = this.props.task ? this.props.task.title : '';
    this.state = {
      isEditingTitle: false,
      taskTitle: title,
      savedTitle: title
    };
  }

  componentWillReceiveProps(nextProps: TopBarGeneralInfoProps) {
    if (nextProps.task && this.props.task && nextProps.task.id !== this.props.task.id) {
      this.setState({ isEditingTitle: false });
    }

    if (!this.state.isEditingTitle && nextProps.task) {
      this.setState({
        taskTitle: nextProps.task.title,
        savedTitle: nextProps.task.title
      });
    }
  }

  private dueDateChange = (newDueDate: number) => {
    if (!this.props.task) {
      return;
    }
    if (typeof this.props.task.id !== 'undefined' && this.props.task.id !== 'NEW') {
      this.props.updateTicket(this.props.task.id, { dueDate: newDueDate });
    }
  };

  private handleBlur = () => {
    if (this.props.task) {
      this.props.updateTicket(this.props.task.id, {
        title: this.state.taskTitle
      });
    }
  };

  render() {
    const topBarRefHeight = document.getElementById('topBarGeneralInfo')?.clientHeight;
    if (topBarRefHeight) {
      document.documentElement.style.setProperty('--topBarHeight', `${topBarRefHeight - 5}px`);
    }

    return (
      <Grid.Column width={10} className="statusLabelColumn topBarGeneralInfo" id="topBarGeneralInfo">
        <Grid.Row>
          <Grid.Column className="longTitle topBarGeneralInfo--column">
            {this.state.isEditingTitle && this.props.task && (
              <TopBarTitleInput
                ticketTypeName={this.props.task?.taskType}
                value={this.state.taskTitle}
                onChange={(taskTitle: string) => {
                  this.setState({ taskTitle });
                }}
                onBlur={(newTitle: string) => {
                  const oldTitle = this.state.savedTitle;

                  this.setState(
                    {
                      taskTitle: newTitle,
                      savedTitle: newTitle,
                      isEditingTitle: false
                    },
                    () => {
                      if (newTitle === oldTitle) {
                        return;
                      }

                      this.handleBlur();
                    }
                  );
                }}
              />
            )}
            {!this.state.isEditingTitle && this.props.task && (
              <>
                <div
                  className="titleContainer"
                  onClick={() => {
                    this.setState({ isEditingTitle: true });
                  }}
                >
                  <Header as="h2">
                    <span dangerouslySetInnerHTML={{ __html: this.state.taskTitle }} />
                  </Header>
                  {!!this.props.titleTemplates.length && (
                    <TitleTemplates
                      updateTitle={(value: string) => {
                        this.setState(
                          {
                            taskTitle: value,
                            savedTitle: value,
                            isEditingTitle: false
                          },
                          () => this.handleBlur()
                        );
                      }}
                      title={this.state.taskTitle}
                      ticketType={this.props.task?.taskType}
                      templates={this.props.titleTemplates}
                    />
                  )}
                </div>
                <div>
                  {FeatureFlags.isFlagOn('ENABLE_EIDENTIFICATION') && (
                    <EIDRelationVerifier
                      contentId={parseInt(this.props.task?.id.substring(3))}
                      metaData={this.props.task?.metaData}
                    />
                  )}

                  <TopBarButtonsGroup />
                </div>
              </>
            )}
          </Grid.Column>
        </Grid.Row>

        {this.props.task && (
          <TopBarLabelList
            dueDateChange={this.dueDateChange}
            task={this.props.task}
            updateTicket={this.props.updateTicket}
            showWeight={this.props.contentType === 'infopage'}
          />
        )}
      </Grid.Column>
    );
  }
}

const mapStateToProps = (state: State) => ({
  task: selectActiveTicket(state),
  titleTemplates: state.templates.titleTemplates
});

const mapDispatchToProps = (dispatch: ThunkAppDispatch) => {
  return {
    updateTicket: (id: string, data: Partial<Ticket>, close = false) => {
      dispatch(updateTicket({ id, ticket: data, closeAfterUpdate: close }));
    }
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TopBarGeneralInfoHOCProps = ConnectedProps<typeof connector>;

export default connector(TopBarGeneralInfo);
