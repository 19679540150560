import { UserStatusType } from '@eeedo/types';
import { v4 as uuidv4 } from 'uuid';

import type { UserTimeLogsPayload } from '@eeedo/types';

import UserTimeLogsApi from './UserTimeLogsApi';
import { consoleLogUserTimeLogs, getCurrentSeconds } from './utils';

const AUTOSAVE_INTERVAL = 1000 * 60 * 5; // 5 minutes
const MAX_TRACKABLE_DURATION = 1000 * 60 * 15; // 15 minutes

class UserTimeLogs {
  private startedAt: number | null = null;
  private trackingID: string | null = null;
  private activeTicketId: string | null = null;
  public unSentLogs: UserTimeLogsPayload[] = [];
  private autoSaveInterval: NodeJS.Timeout | null = null;
  private userStatusType: UserStatusType = UserStatusType.NOTREADY;

  init() {
    this.initializeAutoSave();
  }

  public getActiveTicketId() {
    return this.activeTicketId;
  }

  public setActiveTicketId(ticketId: string): void {
    if (ticketId !== this.activeTicketId) {
      if (this.userStatusType === UserStatusType.READY) {
        this.finishCurrentLogSession();
        this.startNewSession();
      }

      this.activeTicketId = ticketId;
    }
  }

  public getUserStatusType() {
    return this.userStatusType;
  }

  public setUserStatusType(userStatusType: UserStatusType) {
    if (this.userStatusType === UserStatusType.READY && userStatusType === UserStatusType.NOTREADY) {
      if (this.activeTicketId && this.startedAt && this.trackingID) {
        const duration = this.getDuration();
        this.unSentLogs.push({ id: this.activeTicketId!, duration, trackingID: this.trackingID });
      }
      this.sendLogs();
      this.startedAt = null;
      this.trackingID = null;

      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval);
      }
    }

    if (this.userStatusType === UserStatusType.NOTREADY && userStatusType === UserStatusType.READY) {
      this.initializeAutoSave();
      this.startNewSession();
    }

    this.userStatusType = userStatusType;
  }

  public finishCurrentLogSession(): void {
    if (this.isAlreadyCounting()) {
      const duration = this.getDuration();
      this.unSentLogs.push({ id: this.activeTicketId!, duration, trackingID: this.trackingID! });
      this.sendLogs();
    }
    this.resetSession();
  }

  public async sendLogs(): Promise<void> {
    if (this.unSentLogs.length > 0) {
      consoleLogUserTimeLogs('[userTimeLogs]: Sending unsent logs.', this.unSentLogs);
      try {
        await UserTimeLogsApi.sendUserTimeLogs(this.unSentLogs.filter(Boolean));
        this.unSentLogs = [];
      } catch (error) {
        consoleLogUserTimeLogs('[userTimeLogs]: Error sending logs', error);
      }
    }
  }

  private initializeAutoSave(): void {
    this.autoSaveInterval = setInterval(() => {
      this.autoSaveLogs();
    }, AUTOSAVE_INTERVAL);
  }

  private autoSaveLogs(): void {
    if (this.isAlreadyCounting()) {
      // Add current session to unsent logs but do not reset session
      const duration = this.getDuration();
      this.unSentLogs.push({ id: this.activeTicketId!, duration, trackingID: this.trackingID! });
      // Attempt to send all logs
      this.sendLogs();
      this.startNewSession();
    }
  }

  private getDuration() {
    return Math.min(getCurrentSeconds() - this.startedAt!, MAX_TRACKABLE_DURATION);
  }

  private isUserReady() {
    return this.userStatusType === UserStatusType.READY;
  }

  private isAlreadyCounting() {
    return this.activeTicketId && this.startedAt && this.trackingID && this.isUserReady();
  }

  public dispose(): void {
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
    // Ensure current session is logged before disposing
    this.finishCurrentLogSession();
  }

  private startNewSession(): void {
    this.startedAt = getCurrentSeconds();
    this.trackingID = uuidv4();
  }

  private resetSession(): void {
    this.startedAt = null;
    this.activeTicketId = null;
    this.trackingID = null;
  }
}

export default new UserTimeLogs();
