import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import type { Tag } from '@eeedo/types';
import type { FC } from 'react';

import AllTags from './AllTags';
import * as styles from './TicketListItem.style';
import { Label, Popup } from 'src/Components/generic';

interface Props {
  tags: Tag[];
  maxTags?: number;
}

const lengthsMap = { 1: 'TagMaxLength', 2: 'TagSplitLength' };
const getTagElementClassName = (tagsLength: number) => lengthsMap[tagsLength] ?? 'TagMoreThanTwoLength';

const TicketTags: FC<Props> = ({ tags, maxTags = 3 }) => {
  return (
    <div style={styles.tagsContainer}>
      {tags.slice(0, maxTags).map((tag) => (
        <Popup
          content={<AllTags sortedTags={[tag]} />}
          hoverable={true}
          key={tag.id}
          on={'hover'}
          position="bottom right"
          trigger={<AllTags sortedTags={[tag]} className={`ticketListTag ${getTagElementClassName(tags.length)}`} />}
        />
      ))}

      {tags.length > maxTags && (
        <Popup
          content={<AllTags sortedTags={tags} />}
          hoverable
          on="hover"
          position="bottom right"
          trigger={
            <Label className="ticketListTag" style={{ margin: '1.5px', paddingRight: '5px' }}>
              <FontAwesomeIcon icon={faEllipsis} />
            </Label>
          }
        />
      )}
    </div>
  );
};
export default TicketTags;
