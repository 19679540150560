import { faWindowMaximize, faWindowMinimize, faWindowRestore, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Card } from 'semantic-ui-react';

import type { ComponentProps, FC } from 'react';

import * as styles from './FloatingWindow.style';
import { activateTab } from 'src/actions/tabActionsRTK';
import { activateTicket } from 'src/actions/ticketsActions';

import type FloatingWindow from './FloatingWindow';
import type { FloatingWindowState } from 'src/types/FloatingWindow';

interface FloatingWindowHeaderProps extends Pick<ComponentProps<typeof FloatingWindow>, 'styles'> {
  fullScreen: boolean;
  window: FloatingWindowState;

  onClose(): void;
  foldToggle(): void;
  fullScreenToggle?(): void;
}

const FloatingWindowHeader: FC<FloatingWindowHeaderProps> = ({
  window: win,
  fullScreen,
  styles: style,
  onClose,
  foldToggle,
  fullScreenToggle
}) => {
  const dispatch = useDispatch();

  const onTitleClick = useCallback(() => {
    dispatch(activateTicket(win.id));
    dispatch(activateTab(win.id));
  }, [win.id]);

  return (
    <Card.Header style={{ ...styles.cardHeader, ...style?.cardHeader }}>
      <h4 style={{ ...styles.heading, ...style?.heading }} onClick={onTitleClick}>
        {win.title}
      </h4>

      <div style={{ ...styles.controlsWrapper, ...style?.controlsWrapper }}>
        <FontAwesomeIcon
          icon={win.fold ? faWindowRestore : faWindowMinimize}
          style={{ fontSize: '18px' }}
          onClick={foldToggle}
        />
        {typeof fullScreenToggle === 'function' && (
          <FontAwesomeIcon
            icon={fullScreen ? faWindowRestore : faWindowMaximize}
            style={{ fontSize: '18px' }}
            onClick={fullScreenToggle}
          />
        )}
        <FontAwesomeIcon icon={faXmark} style={{ fontSize: '18px' }} onClick={onClose} />
      </div>
    </Card.Header>
  );
};

export default React.memo(FloatingWindowHeader);
