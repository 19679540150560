import type { UserTimeLogsPayload } from '@eeedo/types';

import { consoleLogUserTimeLogs } from './utils';
import { apiClient } from 'src/Utilities/httpClients';

class UserTimeLogsApi {
  public sendUserTimeLogs = async (entries: UserTimeLogsPayload[]) => {
    const data = entries.map((row) => {
      const { id, duration, trackingID } = row;
      return {
        id: parseInt(id.substring(3)),
        duration,
        trackingID
      };
    });
    const res = await apiClient.post('/recordActiveUserTime', data);
    consoleLogUserTimeLogs('[userTimeLogs]: Report sent:', res.status, res.statusText);
  };
}

export default new UserTimeLogsApi();
