import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import * as styles from './Parameter.style';

interface AddOptionButtonProps {
  onAddOption(): void;
}

const AddOptionButton: FC<AddOptionButtonProps> = ({ onAddOption }) => {
  const { t } = useTranslation();

  return (
    <div style={styles.addOptionButtonWrapper}>
      <Popup
        trigger={<Button positive icon="plus" onClick={onAddOption} />}
        content={t('generic.parameters.add_option')}
      />
    </div>
  );
};

export default React.memo(AddOptionButton);
