import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Segment } from 'semantic-ui-react';

import type { FC } from 'react';

import RemoveButton from '../RemoveButton';

import type { OnInputChange, ParameterOption } from 'src/types/Campaign';

interface EnumOptionProps {
  optionIndex: number;
  option: ParameterOption<'enum'>;

  onOptionRemove: (optionIndex: number) => void;
  onOptionUpdate: (optionIndex: number, option: ParameterOption<'enum'>) => void;
}

const EnumOption: FC<EnumOptionProps> = ({ option, optionIndex, onOptionUpdate, onOptionRemove }) => {
  const { t } = useTranslation();

  const updateInputValue = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, { outputValue: option.outputValue, inputValue: value });
    },
    [option, optionIndex]
  );
  const updateOutputValue = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, { inputValue: option.inputValue, outputValue: value });
    },
    [option, optionIndex]
  );
  const removeOption = useCallback(() => {
    onOptionRemove(optionIndex);
  }, [option, optionIndex]);

  return (
    <Segment>
      <RemoveButton description={t('generic.parameters.remove_option')} remove={removeOption} small />
      <Form.Group>
        <Form.Input
          required
          width={8}
          value={option.inputValue}
          error={!option.inputValue}
          label={t('generic.parameters.input_value')}
          onChange={updateInputValue}
        />
        <Form.Input
          required
          width={8}
          value={option.outputValue}
          error={!option.outputValue}
          label={t('generic.parameters.output_value')}
          onChange={updateOutputValue}
        />
      </Form.Group>
    </Segment>
  );
};

export default React.memo(EnumOption);
