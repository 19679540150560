import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iziToast from 'izitoast';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

import Button from 'src/Components/generic/Button/Button';
import TicketNumberInput from 'src/Components/generic/TicketNumberInput';

interface AddRelatedTicketByIdProps {
  placeholder: string;

  disabled?: boolean;

  addRelatedTicketToTicket: (id: number) => void;
}

const AddRelatedTicketById: FC<AddRelatedTicketByIdProps> = ({ placeholder, disabled, addRelatedTicketToTicket }) => {
  const { t } = useTranslation();
  const [id, setId] = useState<number | null>(null);

  const onClick = async () => {
    if (id === null) {
      return;
    }

    try {
      addRelatedTicketToTicket(id);
    } catch (error) {
      console.log('Error failed to add relation to ticket', error);
      iziToast.error({ message: t('CASE_TOAST_COULD_NOT_ADD_RELATION_TO_TASK') + ': ' + id });
    }
  };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      onClick();
    }
  };

  const button = (
    <Button
      className="addRelatedIdTicketButton"
      icon={<FontAwesomeIcon icon={faPlus} />}
      borderless
      onClick={onClick}
      type="primary"
    ></Button>
  );

  return (
    <TicketNumberInput
      disabled={disabled}
      button={button}
      onChange={setId}
      placeholder={placeholder}
      value={id}
      style={{ width: '100%' }}
      onKeyDown={onKeyDown}
    />
  );
};

export default AddRelatedTicketById;
