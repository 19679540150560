import format from 'date-fns/format';
import React from 'react';

import Section from '../generic/Section/Section';
import TimeInput from '../ticketList/SingleLineTicketView/TimeInput';

interface TimeInputComponentProps {
  startDate?: Date;
  endDate?: Date;
  onStartTimeChange: (time: Date) => void;
  onEndTimeChange: (time: Date) => void;
  startTimeLabel: string;
  endTimeLabel: string;
}

const DateTimeInput = ({
  startDate,
  endDate,
  startTimeLabel,
  endTimeLabel,
  onStartTimeChange,
  onEndTimeChange
}: TimeInputComponentProps) => (
  <Section direction="row" justify="space-between" gap={20} padding="0 0.833em 0 0.833em">
    {startDate && (
      <Section direction="column" className="TimeInput" gap={4}>
        <label>{startTimeLabel}:</label>
        <TimeInput onChange={(time) => onStartTimeChange(time)} date={startDate} />
      </Section>
    )}
    {endDate && (
      <Section direction="column" className="TimeInput" gap={4}>
        <label>{endTimeLabel}:</label>
        <TimeInput
          onChange={(time) => onEndTimeChange(time)}
          date={endDate}
          shiftSeconds="59"
          minTime={startDate ? format(startDate, 'HH:mm') : undefined}
        />
      </Section>
    )}
  </Section>
);

export default React.memo(DateTimeInput);
