import type { CSSProperties } from 'react';

export const parameterWrapper: CSSProperties = {
  backgroundColor: 'whitesmoke',
  padding: '15px',
  marginBottom: '25px',
  position: 'relative'
};

export const addOptionButtonWrapper: CSSProperties = { display: 'flex', justifyContent: 'flex-end' };

export const removeButton: CSSProperties = {
  position: 'absolute',
  top: '-5px',
  right: '-5px',
  cursor: 'pointer',
  zIndex: 5
};

export const removeButtonSmall: CSSProperties = {
  position: 'absolute',
  top: '-8px',
  right: '-8px',
  cursor: 'pointer',
  zIndex: 5,
  scale: '.8'
};
