import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Input, Segment } from 'semantic-ui-react';

import type { FC } from 'react';

import RemoveButton from '../RemoveButton';
import { defaultOptionsMap } from 'src/Utilities/campaign';

import type { DateParameterMode, OnDropdownChange, OnInputChange, ParameterOption } from 'src/types/Campaign';

interface DateOptionProps {
  optionIndex: number;
  option: ParameterOption<'date'>;
  mode?: DateParameterMode;

  onOptionRemove: (optionIndex: number) => void;
  onModeUpdate: (optionIndex: number, mode: DateParameterMode) => void;
  onOptionUpdate: (optionIndex: number, option: ParameterOption<'date'>) => void;
}

const DateOption: FC<DateOptionProps> = ({
  mode,
  option,
  optionIndex,
  onOptionUpdate,
  onOptionRemove,
  onModeUpdate
}) => {
  const { t } = useTranslation();
  const isUnixTimestamp = option.formats?.outputFormat === 'unix';

  const onCheckboxChange = useCallback(() => {
    onOptionUpdate(optionIndex, {
      ...option,
      formats: { ...option.formats, outputFormat: isUnixTimestamp ? '' : 'unix' }
    });
  }, [option, optionIndex]);
  const onOutputFormatChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, { ...option, formats: { outputFormat: value } });
    },
    [option, optionIndex]
  );
  const onInputFormatChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, {
        ...option,
        date: { targetVariables: option.date?.targetVariables ?? '', inputFormat: value }
      });
    },
    [option, optionIndex]
  );
  const onTargetVariableChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      onOptionUpdate(optionIndex, {
        ...option,
        date: { inputFormat: option.date?.inputFormat || '', targetVariables: value }
      });
    },
    [option, optionIndex]
  );
  const onModeChange = useCallback<OnDropdownChange>(
    (_e, { value }) => {
      onModeUpdate(optionIndex, value as DateParameterMode);
    },
    [optionIndex]
  );
  const onOffsetTimeUnitChange = useCallback<OnDropdownChange>(
    (_e, { value }) => {
      const { direction, numberOfTimeUnits } = option.offset ?? defaultOptionsMap.date.offset!;
      onOptionUpdate(optionIndex, {
        ...option,
        offset: {
          direction,
          numberOfTimeUnits,
          timeUnit: value as any
        }
      });
    },
    [option, optionIndex]
  );
  const onOffsetDirectionChange = useCallback<OnDropdownChange>(
    (_e, { value }) => {
      const { timeUnit, numberOfTimeUnits } = option.offset ?? defaultOptionsMap.date.offset!;
      onOptionUpdate(optionIndex, {
        ...option,
        offset: {
          direction: value as any,
          numberOfTimeUnits,
          timeUnit
        }
      });
    },
    [option, optionIndex]
  );
  const onNumberOfTimeUnitsChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      const { timeUnit, direction } = option.offset ?? defaultOptionsMap.date.offset!;
      onOptionUpdate(optionIndex, {
        ...option,
        offset: {
          direction,
          numberOfTimeUnits: value,
          timeUnit
        }
      });
    },
    [option, optionIndex]
  );
  const onFixDirectionChange = useCallback<OnDropdownChange>(
    (_e, { value }) => {
      const { timeUnit } = option.fix ?? defaultOptionsMap.date.fix!;
      onOptionUpdate(optionIndex, {
        ...option,
        fix: {
          direction: value as any,
          timeUnit
        }
      });
    },
    [option, optionIndex]
  );
  const onFixTimeUnitChange = useCallback<OnDropdownChange>(
    (_e, { value }) => {
      const { direction } = option.fix ?? defaultOptionsMap.date.fix!;
      onOptionUpdate(optionIndex, {
        ...option,
        fix: {
          direction,
          timeUnit: value as any
        }
      });
    },
    [option, optionIndex]
  );
  const removeOption = useCallback(() => {
    onOptionRemove(optionIndex);
  }, [optionIndex]);

  return (
    <Segment>
      <RemoveButton description={t('generic.parameters.remove_option')} remove={removeOption} small />
      <Form.Group>
        <Form.Field width={8} required error={!option.formats?.outputFormat}>
          <label>{t('generic.parameters.output_format')}</label>
          <Input
            label={
              <Checkbox label={t('generic.parameters.is_unix')} checked={isUnixTimestamp} onChange={onCheckboxChange} />
            }
            placeholder={t('generic.parameters.example_date_format')}
            value={option.formats?.outputFormat || ''}
            onChange={!isUnixTimestamp ? onOutputFormatChange : undefined}
          />
        </Form.Field>
        <Form.Input
          width={8}
          required
          label={t('generic.parameters.target_variable')}
          placeholder={'generic.parameters.example_path'}
          value={option.date?.targetVariables || ''}
          error={!option.date?.targetVariables}
          onChange={onTargetVariableChange}
        />
      </Form.Group>

      <Form.Group widths={'equal'}>
        <Form.Input
          label={t('generic.parameters.input_format')}
          placeholder={'generic.parameters.example_date_format_optional'}
          value={option.date?.inputFormat || ''}
          onChange={onInputFormatChange}
        />
        <Form.Dropdown
          clearable
          selection
          label={t('generic.parameters.date_modification_type')}
          placeholder={t('generic.parameters.select_modification_type_optional')}
          options={[
            { text: t('generic.parameters.offset'), label: t('generic.parameters.offset'), value: 'offset' },
            { text: t('generic.parameters.fix'), label: t('generic.parameters.fix'), value: 'fix' }
          ]}
          onChange={onModeChange}
        />
      </Form.Group>

      {mode === 'fix' && (
        <Form.Group widths={'equal'}>
          <Form.Dropdown
            required
            clearable
            selection
            options={[
              { key: 'startOf', text: t('generic.parameters.start_of'), value: 'startOf' },
              { key: 'endOf', text: t('generic.parameters.end_of'), value: 'endOf' }
            ]}
            label={t('generic.parameters.fix_direction')}
            value={option.fix?.direction || ''}
            error={!option.fix?.direction}
            placeholder={t('generic.parameters.fix_direction')}
            onChange={onFixDirectionChange}
          />
          <Form.Dropdown
            clearable
            selection
            required
            options={[
              { key: 'days', text: t('generic.parameters.days'), value: 'days' },
              { key: 'months', text: t('generic.parameters.months'), value: 'months' },
              { key: 'years', text: t('generic.parameters.years'), value: 'years' }
            ]}
            label={t('generic.parameters.fix_time_unit')}
            value={option.fix?.timeUnit || ''}
            error={!option.fix?.timeUnit}
            placeholder={t('generic.parameters.fix_time_unit')}
            onChange={onFixTimeUnitChange}
          />
        </Form.Group>
      )}

      {mode === 'offset' && (
        <Form.Group widths={'equal'}>
          <Form.Dropdown
            clearable
            selection
            required
            options={[
              { key: 'days', text: t('generic.parameters.days'), value: 'days' },
              { key: 'months', text: t('generic.parameters.months'), value: 'months' },
              { key: 'years', text: t('generic.parameters.years'), value: 'years' }
            ]}
            label={t('generic.parameters.offset_time_unit')}
            value={option.offset?.timeUnit || ''}
            error={!option.offset?.timeUnit}
            placeholder={t('generic.parameters.offset_time_unit')}
            onChange={onOffsetTimeUnitChange}
          />
          <Form.Dropdown
            clearable
            selection
            required
            options={[
              { key: 'forwards', text: t('generic.parameters.forwards'), value: 'forwards' },
              { key: 'backwards', text: t('generic.parameters.backwards'), value: 'backwards' }
            ]}
            label={t('generic.parameters.offset_direction')}
            value={option.offset?.direction || ''}
            error={!option.offset?.direction}
            placeholder={t('generic.parameters.offset_direction')}
            onChange={onOffsetDirectionChange}
          />
          <Form.Input
            required
            type="number"
            placeholder="0"
            label={t('generic.parameters.number_of_time_units')}
            value={option.offset?.numberOfTimeUnits || ''}
            error={!option.offset?.numberOfTimeUnits}
            onChange={onNumberOfTimeUnitsChange}
          />
        </Form.Group>
      )}
    </Segment>
  );
};

export default React.memo(DateOption);
