import { faCalendar, faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { CalendarProps } from 'react-date-range';

import Button from '../generic/Button/Button';
import Popup from '../generic/Popup/Popup';
import Section from '../generic/Section/Section';
import DateTimeCalendar from './DateTimeCalendar';
import DateTimeInput from './DateTimeInput';

import type { OnChangeProps, OnDateRangeChange, Range } from './types';
import type { PopupPosition } from 'src/types/PopupTypes';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DateTimePicker.css';

interface DateTimePickerProps {
  color?: string;
  isRange?: boolean;
  isTimePicker?: boolean;
  isShortCuts?: boolean;
  isClearable?: boolean;
  months?: CalendarProps['months'];
  minDate?: moment.Moment;
  dateFormat?: string;
  startDate?: number | null;
  endDate?: number | null;
  position?: PopupPosition;
  label?: string;
  trigger?: (props: { setIsOpen: (isOpen: boolean) => void }) => React.ReactNode;
  onChange?: ({ date, range }: OnChangeProps) => void;
  onClose?: () => void;
}

const DateTimePicker = ({
  isRange = false,
  isTimePicker = false,
  isShortCuts = false,
  months = 1,
  position = 'bottom center',
  color,
  startDate: initialStartDate,
  endDate: initialEndDate,
  trigger,
  minDate,
  dateFormat: initialDateFormat,
  label,
  onChange
}: DateTimePickerProps) => {
  const { t } = useTranslation();
  const popupRef = useRef<HTMLDivElement>(null);

  const formatDate = initialDateFormat ? initialDateFormat : t('DATEFORMAT_FULL');
  const formatDateTime = `${formatDate} HH:mm`;

  const [isOpen, setIsOpen] = useState(false);
  const [renderDateValue, setRenderDateValue] = useState<string>('');
  const [range, setRange] = useState<Range>({
    startDate: initialStartDate ? moment.unix(initialStartDate).toDate() : undefined,
    endDate: initialEndDate ? moment.unix(initialEndDate).toDate() : undefined
  });

  const dateFormat = useMemo(() => (isTimePicker ? formatDateTime : formatDate), [isTimePicker]);

  const { startDate, endDate } = range;

  const onDateRangeChange = useCallback<OnDateRangeChange>((ranges) => {
    const { startDate, endDate } = ranges['selection'] ?? {};
    const updatedRange = {
      startDate,
      endDate: endDate ? setMinutes(setHours(endDate, 23), 59) : endDate
    };
    setRange(updatedRange);
    handleOnChange(updatedRange); // use the updated value directly
  }, []);

  const onChangeDate = useCallback((date: Date) => {
    const updatedRange = { startDate: date };
    setRange(updatedRange);
    handleOnChange(updatedRange);
  }, []);

  const handleOnChange = useCallback(
    (nextRange = range) => {
      onChange?.({
        date: nextRange.startDate ? moment(nextRange.startDate).unix() : null,
        range: {
          startDate: nextRange.startDate ? moment(nextRange.startDate).unix() : undefined,
          endDate: nextRange.endDate ? moment(nextRange.endDate).unix() : undefined
        }
      });
    },
    [startDate, endDate, onChange]
  );

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen]);

  const handleResetRange = useCallback(() => {
    setRange({
      startDate: initialStartDate ? moment.unix(initialStartDate).toDate() : undefined,
      endDate: initialEndDate ? moment.unix(initialEndDate).toDate() : undefined
    });
  }, [initialStartDate, initialEndDate, setRange]);

  const clearDate = useCallback(() => {
    setRange({ startDate: undefined, endDate: undefined });
  }, [setRange]);

  const onStartTimeChange = useCallback(
    (date: Date) => {
      setRange({ startDate: date, endDate });
    },
    [endDate, setRange]
  );
  const onEndTimeChange = useCallback(
    (date: Date) => {
      setRange({ startDate, endDate: date });
    },
    [startDate, setRange]
  );

  // change the inputValue on date change
  useEffect(() => {
    if (startDate && endDate) {
      setRenderDateValue(`${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`);
    } else if (startDate) {
      setRenderDateValue(format(startDate, dateFormat));
    } else {
      setRenderDateValue(t('SELECT_DATE'));
    }
  }, [startDate, endDate, dateFormat]);

  useEffect(() => {
    setRange({
      startDate: initialStartDate ? moment.unix(initialStartDate).toDate() : undefined,
      endDate: initialEndDate ? moment.unix(initialEndDate).toDate() : undefined
    });
  }, [initialStartDate, initialEndDate]);

  const onSelectClick = useCallback(() => {
    handleOnChange();
    handleClose();
  }, [handleOnChange, handleClose]);

  const onResetClick = useCallback(() => {
    handleResetRange();
    handleOnChange();
  }, [handleResetRange, handleClose]);

  return (
    <Popup
      on="click"
      noPadding
      open={isOpen}
      position={position}
      onOpen={handleOpen}
      onClose={handleClose}
      trigger={
        trigger?.({ setIsOpen }) || (
          <div>
            {label ? <label>{label}</label> : null}
            <Button
              fullWidth
              type="normal"
              onClick={() => setIsOpen(true)}
              iconLeft={<FontAwesomeIcon icon={faCalendar} />}
              iconRight={startDate ? <FontAwesomeIcon icon={faClose} onClick={clearDate} /> : undefined}
              content={renderDateValue}
            />
          </div>
        )
      }
    >
      <div ref={popupRef}>
        <div className="datePickerWrap">
          <Section direction="column" gap={8}>
            <DateTimeCalendar
              isRange={isRange}
              isShortCuts={isShortCuts}
              months={months}
              color={color}
              minDate={minDate?.toDate()}
              onChangeDate={onChangeDate}
              onDateRangeChange={onDateRangeChange}
            />
            {isTimePicker && (
              <DateTimeInput
                startDate={startDate}
                endDate={endDate}
                onStartTimeChange={onStartTimeChange}
                onEndTimeChange={onEndTimeChange}
                startTimeLabel={t('DATEPICKER_START_TIME')}
                endTimeLabel={t('DATEPICKER_END_TIME')}
              />
            )}
          </Section>
        </div>
        <Section gap={8} direction="row" className="datePickerWrap-footer">
          <Button content={t('GENERAL_SELECT')} type="primary" onClick={onSelectClick} />
          <Button content={t('GENERAL_RESET')} onClick={onResetClick} />
        </Section>
      </div>
    </Popup>
  );
};

export default React.memo(DateTimePicker);
