export enum HookEventTypes {
  TICKET_ADDED = 'TICKET_ADDED',
  INFO_PAGE_ADDED = 'INFO_PAGE_ADDED',
  DELEGATION_ADDED = 'DELEGATION_ADDED',
  DELEGATION_REMOVED = 'DELEGATION_REMOVED',
  ENTITY_ATTACHED = 'ENTITY_ATTACHED',
  HELPER_ADDED = 'HELPER_ADDED',
  COMMENT_ADDED = 'COMMENT_ADDED',
  STATUS_CHANGED = 'STATUS_CHANGED',
  TAG_ADDED_TO_TICKET = 'TAG_ADDED_TO_TICKET',
  CALL_RESCHEDULED = 'CALL_RESCHEDULED',
  TASKTYPE_CHANGED = 'TASKTYPE_CHANGED',
  TICKET_STATUS_CHANGED_TO_DONE = 'TICKET_STATUS_CHANGED_TO_DONE',
  INTEGRATION_COMPLETED = 'INTEGRATION_COMPLETED'
}

export enum RpaRuleTrigger {
  DelegationAdded = 'delegation_added',
  DelegationRemoved = 'delegation_removed',
  EntityAttached = 'entity_attached',
  Helper = 'helper',
  Ticket = 'ticket',
  InfoPage = 'infopage',
  Comment = 'comment',
  StatusChanged = 'status_changed',
  TaskTypeChanged = 'taskType_changed',
  TagAdded = 'tag_added',
  CallRescheduled = 'call_rescheduled',
  IntegrationCompleted = 'integration_completed'
}

export const hookEventMap = {
  [HookEventTypes.TICKET_ADDED]: RpaRuleTrigger.Ticket,
  [HookEventTypes.INFO_PAGE_ADDED]: RpaRuleTrigger.InfoPage,
  [HookEventTypes.DELEGATION_ADDED]: RpaRuleTrigger.DelegationAdded,
  [HookEventTypes.DELEGATION_REMOVED]: RpaRuleTrigger.DelegationRemoved,
  [HookEventTypes.ENTITY_ATTACHED]: RpaRuleTrigger.EntityAttached,
  [HookEventTypes.HELPER_ADDED]: RpaRuleTrigger.Helper,
  [HookEventTypes.COMMENT_ADDED]: RpaRuleTrigger.Comment,
  [HookEventTypes.STATUS_CHANGED]: RpaRuleTrigger.StatusChanged,
  [HookEventTypes.TASKTYPE_CHANGED]: RpaRuleTrigger.TaskTypeChanged,
  [HookEventTypes.TAG_ADDED_TO_TICKET]: RpaRuleTrigger.TagAdded,
  [HookEventTypes.CALL_RESCHEDULED]: RpaRuleTrigger.CallRescheduled,
  [HookEventTypes.INTEGRATION_COMPLETED]: RpaRuleTrigger.IntegrationCompleted
};

export enum RpaRuleNodeType {
  Trigger = 'trigger',
  Condition = 'condition',
  Action = 'action',
  Delay = 'delay'
}

export enum RpaRuleReplyType {
  REPLY = 'REPLY',
  REPLY_ALL = 'REPLY_ALL',
  FORWARD = 'FORWARD',
  TEMPLATED_MESSAGE = 'TEMPLATED_MESSAGE'
}

export type ConditionGenericType = string | string[] | number | number[] | EntityConditionValue;

export interface EntityConditionValue {
  _type: string;
  field: string;
  value: string;
}

export interface Condition<T> {
  fact: string;
  path: string;
  subPath?: T;
  operator: string;
  value: T;
}

export interface RpaRule {
  id: number;
  ruleId: number;
  version: number;
  trigger: RpaRuleTrigger;
  ruleName: string;
  priority: number;
  alwaysRun: boolean;
  active: boolean;
  nodes: RpaRuleNode[];
  created: number;
  updated: number;
}

export type RpaRuleNode = RpaRuleNodeTrigger | RpaRuleNodeCondition | RpaRuleNodeAction | RpaRuleNodeDelay;
export type RpaRuleNodePartial =
  | Partial<RpaRuleNodeTrigger>
  | Partial<RpaRuleNodeCondition>
  | Partial<RpaRuleNodeAction>
  | Partial<RpaRuleNodeDelay>;

export interface RpaRuleNodeBase {
  id: number;
  type: RpaRuleNodeType;
  position: { x: number; y: number };
}

export interface RpaRuleNodeTrigger extends RpaRuleNodeBase {
  type: RpaRuleNodeType.Trigger;
  targets?: number[];
}

export interface RpaRuleNodeCondition extends RpaRuleNodeBase {
  type: RpaRuleNodeType.Condition;
  data: {
    type: 'all' | 'any';
    listOfConditions: Condition<ConditionGenericType>[];
  };
  targetsTrue?: number[];
  targetsFalse?: number[];
}

export interface RpaRuleNodeAction<T = RpaRuleNodeActionData> extends RpaRuleNodeBase {
  type: RpaRuleNodeType.Action;
  entity: RpaRuleActionEntity;
  actionType: RpaRuleActionType;
  data: T;
  targets?: number[];
}

export type RpaRuleNodeDelay = {
  type: RpaRuleNodeType.Delay;
  targets?: number[];
} & (RpaRuleNodeDelaySimple | RpaRuleNodeDelayRelative | RpaRuleNodeDelayAbsolute);

export interface RpaRuleNodeDelaySimple extends RpaRuleNodeBase {
  delayType: RpaRuleDelayType.SIMPLE;
  delayMs: number;
}

export interface RpaRuleNodeDelayRelative extends RpaRuleNodeBase {
  delayType: RpaRuleDelayType.RELATIVE;
  delayMs: number;
  timestampFieldPath?: string;
  timestampFieldSubPath?:
    | string
    | {
        _type: string;
        field: string;
      };
}

export interface RpaRuleNodeDelayAbsolute extends RpaRuleNodeBase {
  delayType: RpaRuleDelayType.ABSOLUTE;
  absoluteDate: number;
}

export enum RpaRuleActionEntity {
  Ticket = 'ticket',
  CaseDetails = 'case_details',
  NewComment = 'new_comment',
  AttachEntity = 'attach_entity',
  AiFunction = 'ai_function',
  Webhook = 'webhook'
}

export enum RpaRuleDelayType {
  ABSOLUTE = 'absolute',
  RELATIVE = 'relative',
  SIMPLE = 'simple'
}

export enum RpaRuleActionType {
  AI_FUNCTION_INVOCATION = 'AI_FUNCTION_INVOCATION',
  ATTACH_ENTITY = 'ATTACH_ENTITY',
  REMOVE_ALL_TICKET_DELEGATIONS = 'REMOVE_ALL_TICKET_DELEGATIONS',
  REMOVE_TICKET_TAG = 'REMOVE_TICKET_TAG',
  REMOVE_TICKET_WORKING_ON = 'REMOVE_TICKET_WORKING_ON',
  REPLY_COMMENT = 'REPLY_COMMENT',
  TICKET_CHANNEL = 'TICKET_CHANNEL',
  TICKET_DELEGATIONS = 'TICKET_DELEGATIONS',
  TICKET_DUE_DATE = 'TICKET_DUE_DATE',
  TICKET_PRIORITY = 'TICKET_PRIORITY',
  TICKET_STATE = 'TICKET_STATE',
  TICKET_TAG = 'TICKET_TAG',
  TICKET_TITLE = 'TICKET_TITLE',
  TICKET_TYPE = 'TICKET_TYPE',
  CASE_DETAILS = 'CASE_DETAILS',
  WEBHOOK = 'WEBHOOK'
}

export enum RpaRuleDropdownType {
  CHANNEL = 'CHANNEL',
  DIRECTION = 'DIRECTION',
  ENTITY_TYPES = 'ENTITY_TYPES',
  STATUS = 'STATUS',
  TICKET_DELEGATIONS = 'TICKET_DELEGATIONS',
  TICKET_STATE = 'TICKET_STATE',
  TICKET_TAG = 'TICKET_TAG',
  TICKET_TYPE = 'TICKET_TYPE',
  TIME_UNITS = 'TIME_UNITS'
}

export type RpaRuleNodeActionData = { [key: string]: any };

export interface RpaRuleActionCaseDetailsData extends RpaRuleNodeActionData {
  fields: {
    name: string;
    type: RpaRuleActionCaseDetailsFieldType;
    value: any;
  }[];
}

export enum RpaRuleActionCaseDetailsFieldType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  JSON = 'json',
  EMPTY = 'empty'
}

export enum RpaRuleActionEntitySearchBy {
  ID = 'id'
}

export type RpaRuleNodeResult = null | string | boolean;

export enum RpaRuleEIdentificationStatus {
  SUCCESS = 'success',
  FAILED = 'failed'
}
