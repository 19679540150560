import { enUS } from 'date-fns/locale';
import React from 'react';
import { Calendar, DateRange, DateRangePicker } from 'react-date-range';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';
import type { DateRangePickerProps } from 'react-date-range';

import { getLocale } from '../../utils/dateUtils';

import type { OnDateRangeChange } from './types';

type CommonProps = Pick<
  DateRangePickerProps,
  'color' | 'months' | 'direction' | 'rangeColors' | 'showMonthAndYearPickers' | 'locale'
>;

interface DateTimeCalendarProps extends CommonProps {
  isRange: boolean;
  isShortCuts: boolean;

  startDate?: Date;
  endDate?: Date;
  minDate?: Date;

  onChangeDate(date: Date): void;
  onDateRangeChange: OnDateRangeChange;
}

const DateTimeCalendar: FC<DateTimeCalendarProps> = ({
  color = '#7F56DA',
  months,
  isRange,
  endDate,
  minDate,
  startDate,
  isShortCuts,
  onChangeDate,
  onDateRangeChange
}) => {
  const { i18n } = useTranslation();
  const commonProps: CommonProps = {
    locale: getLocale(i18n.language),
    color,
    months,
    direction: 'horizontal',
    rangeColors: [color],
    showMonthAndYearPickers: false
  };

  if (isRange && isShortCuts) {
    return (
      <DateRangePicker
        ranges={[{ startDate, endDate, key: 'selection' }]}
        onChange={onDateRangeChange}
        minDate={minDate}
        {...commonProps}
      />
    );
  }

  if (isRange) {
    return (
      <DateRange
        locale={enUS}
        ranges={[{ startDate, endDate, key: 'selection' }]}
        onChange={onDateRangeChange}
        {...commonProps}
      />
    );
  }

  return <Calendar locale={enUS} date={startDate} onChange={(date) => onChangeDate(date)} {...commonProps} />;
};

export default React.memo(DateTimeCalendar);
