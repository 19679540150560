import React from 'react';
import { Button, Popup } from 'semantic-ui-react';

import type { FC } from 'react';

import * as styles from './Parameter.style';

import type { OnButtonClick } from 'src/types/Campaign';

interface RemoveButtonProps {
  description: string;
  remove: OnButtonClick;
  small?: boolean;
}

const RemoveButton: FC<RemoveButtonProps> = ({ description, small, remove }) => (
  <Popup
    trigger={
      <Button
        negative
        size="mini"
        icon="remove"
        style={small ? styles.removeButtonSmall : styles.removeButton}
        onClick={remove}
      />
    }
    content={description}
  />
);

export default React.memo(RemoveButton);
