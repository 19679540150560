import React, { useCallback, useEffect, useState } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import { Card } from 'semantic-ui-react';

import type { ComponentProps } from 'react';
import type { DraggableEventHandler } from 'react-draggable';
import type { ResizeCallbackData } from 'react-resizable';

import { DEFAULT_HEIGHT, DEFAULT_WIDTH, WINDOW_OFFSET } from './constants';
import * as styles from './FloatingWindow.style';
import FloatingWindowHeader from './FloatingWindowHeader';

import type { FloatingWindowStyles } from './types';
import type { FloatingWindowState } from 'src/types/FloatingWindow';

import '../../../../../../node_modules/react-resizable/css/styles.css';
import './FloatingWindow.css';

interface FloatingWindowProps {
  children: React.ReactNode;
  index: number;
  window: FloatingWindowState;
  styles?: Partial<FloatingWindowStyles>;
  extraContent?: React.ReactNode;
  minConstraints?: [number, number];

  onClose(): void;
  foldToggle(): void;
  onDragStart(...args: Parameters<DraggableEventHandler>): void;
  onDragStop(...args: Parameters<DraggableEventHandler>): void;
  onResizeStop(e: React.SyntheticEvent, data: ResizeCallbackData): void;
}

const FloatingWindow = ({
  children,
  window: win,
  index,
  styles: style,
  extraContent,

  onClose,
  foldToggle,
  onDragStart,
  onDragStop,
  onResizeStop,
  minConstraints = [150, 50]
}: FloatingWindowProps) => {
  const currentWidth = win.width ?? DEFAULT_WIDTH;
  const currentHeight = win.height ?? DEFAULT_HEIGHT;
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: currentWidth,
    height: currentHeight
  });
  const [fullScreen, setFullScreen] = useState(false);

  const { innerWidth, innerHeight } = window;
  // Compensates for going beyond the window boundaries
  const winX = win.x! > innerWidth ? win.x! - (win.x! - innerWidth + win.width!) : win.x;
  const winY = win.y! > innerHeight ? win.y! - (win.y! - innerHeight + win.height!) : win.y;

  // Handles full screen mode
  const x = fullScreen ? WINDOW_OFFSET / 2 : winX ?? 0;
  const y = fullScreen ? WINDOW_OFFSET / 2 : winY ?? 0;
  const width = fullScreen ? innerWidth - WINDOW_OFFSET : size.width;
  const height = fullScreen ? innerHeight - WINDOW_OFFSET : size.height;

  const [bounds, setBounds] = useState({
    top: 0,
    left: 0,
    right: window.innerWidth - width,
    bottom: window.innerHeight - height
  });

  useEffect(() => {
    setSize({ width: currentWidth, height: currentHeight });
  }, [win.width, win.height]);

  // Updates floating windows boundaries on resize
  useEffect(() => {
    const updateBounds = () => {
      setBounds({ left: 0, top: 0, right: window.innerWidth - width, bottom: window.innerHeight - height });
    };

    window.addEventListener('resize', updateBounds);
    return () => window.removeEventListener('resize', updateBounds);
  }, []);

  const fullScreenToggle = useCallback(() => {
    setFullScreen(!fullScreen);
  }, [fullScreen]);
  const onResize = useCallback<NonNullable<ComponentProps<typeof Resizable>['onResize']>>(
    (_e, { size }) => {
      setSize(size);
    },
    [setSize]
  );

  const card = (
    <Card
      style={{
        ...styles.card({ fullScreen, width: `${width}px`, height: `${height}px`, index }),
        ...style?.card
      }}
    >
      <FloatingWindowHeader
        window={win}
        styles={style}
        fullScreen={fullScreen}
        onClose={onClose}
        foldToggle={foldToggle}
        fullScreenToggle={fullScreenToggle}
      />
      <div style={{ ...styles.contentWrapper, ...style?.contentWrapper }}>
        <div className="not-draggable" style={{ ...styles.content, ...style?.content }}>
          {children}
        </div>
      </div>
      {!!extraContent && <Card.Content>{extraContent}</Card.Content>}
    </Card>
  );

  if (fullScreen) {
    return card;
  }

  return (
    <Draggable
      cancel=".react-resizable-handle, .not-draggable"
      onStart={onDragStart}
      onStop={onDragStop}
      bounds={bounds}
      position={{ x, y }}
    >
      <Resizable
        className="react-resizable-handle-se_position"
        width={width}
        height={height}
        minConstraints={minConstraints}
        onResize={onResize}
        onResizeStop={onResizeStop}
      >
        {card}
      </Resizable>
    </Draggable>
  );
};

export default React.memo(FloatingWindow);
