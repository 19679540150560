import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import type { FC } from 'react';

import type { OnInputChange, Parameter } from 'src/types/Campaign';

interface StringParameterProps {
  parameter: Parameter;
  onVariableChange: OnInputChange;
}

const StringParameter: FC<StringParameterProps> = ({ parameter, onVariableChange }) => {
  const { t } = useTranslation();

  return (
    <Form.Input
      fluid
      required
      width={6}
      label={t('generic.parameters.target_variable')}
      placeholder={t('generic.parameters.variable_name')}
      value={parameter.data.targetVariables}
      error={!parameter.data.targetVariables}
      onChange={onVariableChange}
    />
  );
};

export default React.memo(StringParameter);
