import { faRemove, faSend } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEmpty } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Icon, Table } from 'semantic-ui-react';

import type { CSSProperties, FC } from 'react';

import Button from '../generic/Button/Button';
import Label from '../generic/Label/Label';
import Modal from '../generic/Modal/Modal';
import { trimAndSplitRecipients } from 'src/Components/Utilities/recipients';
import { TicketStatusAfterCommentSubmit } from 'src/types/Salesforce';

import type { Attachment } from 'src/types/Ticket';

export interface ReplyEmailSubmitModalProps {
  isOpen: boolean;
  canCloseAsDone: boolean;
  content: string;
  cc: string;
  subject: string;
  bcc: string;
  to: string;
  from: string;
  selectedAttachmentIds: string[];
  attachments: Attachment[];
  closeWithStatus?: TicketStatusAfterCommentSubmit;

  closeModal: () => void;
  submitEmail: (closeWithStatus?: TicketStatusAfterCommentSubmit) => Promise<void>;
}

const recipientStyle: CSSProperties = { margin: '2px' };
const descriptionStyle: CSSProperties = { padding: '20px' };
const getLabelForCloseWithStatus = (status: TicketStatusAfterCommentSubmit) => {
  switch (status) {
    case TicketStatusAfterCommentSubmit.todo: {
      return 'GENERAL_SEND_AND_CLOSE_AS_TODO';
    }
    case TicketStatusAfterCommentSubmit.doing: {
      return 'GENERAL_SEND_AND_CLOSE_AS_DOING';
    }
    case TicketStatusAfterCommentSubmit.done: {
      return 'GENERAL_SEND_AND_CLOSE';
    }
  }
};

const ReplyEmailSubmitModal: FC<ReplyEmailSubmitModalProps> = ({
  to,
  cc,
  bcc,
  from,
  isOpen,
  subject,
  attachments,
  closeWithStatus,
  selectedAttachmentIds,
  submitEmail,
  closeModal
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const getSelectedAttachments = (selectAttachmentIds: string[]): Attachment[] =>
    attachments.filter(
      (attachment: Attachment) =>
        !attachment.isQuarantined && !attachment.deprecated && selectAttachmentIds.includes(attachment.id)
    );

  const submit = useCallback(async () => {
    setIsLoading(true);
    try {
      await submitEmail(closeWithStatus);
    } finally {
      closeModal();
      setIsLoading(false);
    }
  }, [closeWithStatus, closeModal, setIsLoading]);

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      header={t('COMMENT_SEND_AS_EMAIL_QUESTION')}
      description={
        <div style={descriptionStyle}>
          <Table celled padded>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{t('ADD_COMMENT_TITLE')}</Table.Cell>
                <Table.Cell>{subject}</Table.Cell>
              </Table.Row>

              <Table.Row>
                <Table.Cell>{t('ADD_COMMENT_SENDER_EMAIL_ADDRESS')}</Table.Cell>
                <Table.Cell>{from}</Table.Cell>
              </Table.Row>

              {selectedAttachmentIds.length > 0 && (
                <Table.Row>
                  <Table.Cell>{t('ADD_COMMENT_ATTACHMENT_LABEL')}</Table.Cell>
                  <Table.Cell>
                    {getSelectedAttachments(selectedAttachmentIds).map((attachment, index) => (
                      <Label key={`reply-submit-modal-selected-attachments-${index}`}>{attachment.fileName}</Label>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
          <Divider horizontal>
            <Icon name="arrow down" />
          </Divider>
          <Table celled padded>
            <Table.Body>
              {to && (
                <Table.Row>
                  <Table.Cell>{t('ADD_COMMENT_RECEPIENTS')}</Table.Cell>
                  <Table.Cell>
                    {trimAndSplitRecipients(to)?.map((recipient: string, index) => (
                      <React.Fragment key={`reply-submit-modal-selected-recipient-to-${index}`}>
                        {!isEmpty(recipient) && <Label style={recipientStyle}>{recipient}</Label>}
                      </React.Fragment>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
              {cc && (
                <Table.Row>
                  <Table.Cell>CC</Table.Cell>
                  <Table.Cell>
                    {trimAndSplitRecipients(cc)?.map((recipient: string, index) => (
                      <React.Fragment key={`reply-submit-modal-selected-recipient-cc-${index}`}>
                        {!isEmpty(recipient) && <Label style={recipientStyle}>{recipient}</Label>}
                      </React.Fragment>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
              {bcc && (
                <Table.Row>
                  <Table.Cell>BCC</Table.Cell>
                  <Table.Cell>
                    {trimAndSplitRecipients(bcc)?.map((recipient: string, index) => (
                      <React.Fragment key={`reply-submit-modal-selected-recipient-bcc-${index}`}>
                        {!isEmpty(recipient) && <Label style={recipientStyle}>{recipient}</Label>}
                      </React.Fragment>
                    ))}
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </div>
      }
      footer={
        <>
          <Button
            disabled={isLoading}
            iconLeft={<FontAwesomeIcon icon={faRemove} />}
            onClick={() => closeModal()}
            type="error"
            negative
            icon
          >
            {t('GENERAL_CANCEL')}
          </Button>
          <Button
            disabled={isLoading}
            iconLeft={<FontAwesomeIcon icon={faSend} />}
            loading={isLoading}
            onClick={submit}
            type="primary"
            icon
            primary
          >
            {t(closeWithStatus ? getLabelForCloseWithStatus(closeWithStatus) : 'GENERAL_SEND')}
          </Button>
        </>
      }
      closeOnDocumentClick
      closeOnDimmerClick
      closeOnEscape
      centered
    />
  );
};

export default React.memo(ReplyEmailSubmitModal);
