import type { CSSProperties } from 'react';

import { WINDOW_Z_INDEX } from './constants';

export const card: (args: { fullScreen: boolean; width: string; height: string; index: number }) => CSSProperties = ({
  fullScreen,
  width,
  height,
  index
}) => ({
  top: fullScreen ? '50%' : 0,
  left: fullScreen ? '50%' : 0,
  transform: fullScreen ? 'translate(-50%, -50%)' : undefined,
  width,
  height,
  position: 'fixed',
  cursor: 'pointer',
  border: '5px solid #eee',
  borderRadius: '24px',
  backgroundColor: 'white',
  zIndex: WINDOW_Z_INDEX + index + (fullScreen ? 100 : 0),
  boxShadow: '0px 12px 32px 0px rgba(17, 17, 17, 0.08)'
});

export const contentWrapper: CSSProperties = { overflow: 'hidden', height: '100%', borderRadius: '0 0 19px 19px' };

export const content: CSSProperties = { overflow: 'auto', height: '100%', padding: '0 !important' };

export const cardHeader: CSSProperties = {
  padding: '14px',
  display: 'flex',
  justifyContent: 'space-between'
};

export const heading: CSSProperties = {
  maxHeight: '20px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
};

export const controlsWrapper: CSSProperties = { display: 'flex', paddingLeft: '10px', cursor: 'pointer', gap: '10px' };
