import moment from 'moment';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form, Icon } from 'semantic-ui-react';

import type { FC } from 'react';

import Button from './Button/Button';
import Modal from './Modal/Modal';
import TimeInputWithDropdown from './TimeInputWithDropdown';
import DateTimePicker from 'src/Components/DateTimePicker/DateTimePicker';
import { DATE_TIME_FORMAT } from 'src/Utilities/dates';

interface Props {
  isOpen: boolean;
  dueDate: number | null;

  changeDueDate: (newDueDate: number) => void;
  closeModal: () => void;
}

export const DATE_TIME_FORMAT_DATEPICKER = DATE_TIME_FORMAT.replace('DD', 'dd').replace('YYYY', 'yyyy');

const DueDatePickerModal: FC<Props> = ({ closeModal, dueDate, changeDueDate, isOpen }) => {
  const { t } = useTranslation();
  const [datePickerValue, setDatepickerValue] = useState(dueDate || moment().unix());
  const [secondsInputValue, setSecondsInputValue] = useState(0);

  const onSave = () => {
    changeDueDate(datePickerValue);
    closeModal();
  };

  const onSecondsInputChange = (value: number) => {
    setSecondsInputValue(value);
    setDatepickerValue(moment().add(value, 'seconds').unix());
  };

  const onDatePickerChange = (value: number) => {
    setDatepickerValue(value);
    setSecondsInputValue(0);
  };

  return (
    <Modal
      open={isOpen}
      className="dueDateDatepicker__modal"
      onClose={closeModal}
      size="tiny"
      closeOnDimmerClick
      centered
      closeOnEscape
      header={
        <>
          {t('GENERAL_EDIT')} {t('GENERAL_DUE_DATE')}
        </>
      }
      footer={
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
          <Button type="positive" icon onClick={onSave}>
            <Icon name="check" />
            {t('GENERAL_SAVE')}
          </Button>
          <Button onClick={closeModal} type="error" icon>
            <Icon name="times" />
            {t('GENERAL_CANCEL')}
          </Button>
        </div>
      }
    >
      <Form>
        <Form.Field style={{ marginBottom: '30px' }}>
          <label>{t('GENERAL_OFFSET')}</label>
          <TimeInputWithDropdown value={secondsInputValue} onChange={(value: number) => onSecondsInputChange(value)} />
        </Form.Field>
        <Divider style={{ marginBottom: '30px' }} horizontal>
          {t('GENERAL_OR')}
        </Divider>
        <Form.Field>
          <label>{t('GENERAL_FIXED_DATE')}</label>
          <DateTimePicker
            isTimePicker
            startDate={datePickerValue}
            onChange={({ date }) => onDatePickerChange(date!)}
            dateFormat={DATE_TIME_FORMAT_DATEPICKER}
          />
        </Form.Field>
      </Form>
    </Modal>
  );
};

export default React.memo(DueDatePickerModal);
