import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'semantic-ui-react';

import type { FC } from 'react';

import DateParameter from './DateParameter/DateParameter';
import EnumParameter from './EnumParameter/EnumParameter';
import MatchArrayParameter from './MatchArrayParameter/MatchArrayParameter';
import * as styles from './Parameter.style';
import ParameterType from './ParameterType';
import PushToArrayParameter from './PushToArrayParameter/PushToArrayParameter';
import RemoveButton from './RemoveButton';
import StringParameter from './StringParameter';

import type {
  DateParameterMode,
  OnButtonClick,
  OnDropdownChange,
  OnInputChange,
  ParameterArrayOptionType,
  ParameterCallbacks,
  ParameterOption,
  Parameter as TParameter,
  ParameterType as TParameterType
} from 'src/types/Campaign';

interface ParameterProps extends ParameterCallbacks {
  index: number;
  parameter: TParameter;
  dateParametersMode: Record<number, DateParameterMode>;
}

const Parameter: FC<ParameterProps> = ({
  index,
  parameter,
  dateParametersMode,

  changeType,
  changeParameter,
  onRemoveParameter,
  addOption,
  updateOption,
  removeOption,
  updateMode
}) => {
  const { t } = useTranslation();

  const onTypeChange = useCallback<OnDropdownChange>(
    (_e, { value }) => {
      changeType({ type: value as TParameterType, index });
    },
    [index]
  );
  const onPathChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      changeParameter({ prop: 'path', value, index });
    },
    [index]
  );
  const onVariableChange = useCallback<OnInputChange>(
    (_e, { value }) => {
      changeParameter({ prop: 'data', value: { targetVariables: value }, index });
    },
    [index]
  );
  const removeParameter = useCallback<OnButtonClick>(() => {
    onRemoveParameter(index);
  }, [index]);
  const onAddOption = useCallback(
    (type: ParameterArrayOptionType) => {
      addOption({ type, index });
    },
    [index]
  );
  const onOptionUpdate = useCallback<
    <T extends ParameterArrayOptionType>(args: { optionIndex: number; type: T; option: ParameterOption<T> }) => void
  >(
    ({ optionIndex, option, type }) => {
      updateOption({ index, optionIndex, type, option });
    },
    [index]
  );
  const onOptionRemove = useCallback<(args: { optionIndex: number; type: ParameterArrayOptionType }) => void>(
    ({ optionIndex, type }) => {
      removeOption({ index, optionIndex, type });
    },
    [index]
  );
  const onModeUpdate = useCallback(
    ({ optionIndex, mode }: { optionIndex: number; mode: DateParameterMode }) => {
      updateMode({ index, optionIndex, mode });
    },
    [index]
  );

  const parameterType = parameter.type as TParameterType;

  return (
    <div style={styles.parameterWrapper}>
      <RemoveButton description={t('generic.parameters.remove_parameter')} remove={removeParameter} />
      <Form.Group>
        <ParameterType parameter={parameter} onTypeChange={onTypeChange} />
        <Form.Input
          fluid
          required
          label={t('generic.parameters.path')}
          value={parameter.path}
          error={!parameter.path}
          onChange={onPathChange}
          placeholder={t('generic.parameters.example_path')}
          width={parameterType === 'string' ? 6 : 12}
        />
        {parameterType === 'string' && <StringParameter parameter={parameter} onVariableChange={onVariableChange} />}
      </Form.Group>
      {parameterType === 'date' && (
        <DateParameter
          index={index}
          parameter={parameter}
          dateParametersMode={dateParametersMode}
          onAddOption={onAddOption}
          onOptionUpdate={onOptionUpdate}
          onOptionRemove={onOptionRemove}
          onModeUpdate={onModeUpdate}
        />
      )}
      {parameterType === 'enum' && (
        <EnumParameter
          index={index}
          parameter={parameter}
          onAddOption={onAddOption}
          onOptionUpdate={onOptionUpdate}
          onOptionRemove={onOptionRemove}
          changeParameter={changeParameter}
        />
      )}
      {parameterType === 'matchArray' && (
        <MatchArrayParameter
          index={index}
          parameter={parameter}
          onAddOption={onAddOption}
          onOptionUpdate={onOptionUpdate}
          onOptionRemove={onOptionRemove}
          changeParameter={changeParameter}
        />
      )}
      {parameterType === 'pushToArray' && (
        <PushToArrayParameter
          index={index}
          parameter={parameter}
          onAddOption={onAddOption}
          onOptionUpdate={onOptionUpdate}
          onOptionRemove={onOptionRemove}
        />
      )}
    </div>
  );
};

export default React.memo(Parameter);
