import React from 'react';

import type { FC } from 'react';

import * as styles from './TicketListItem.style';
import { Label, Popup } from 'src/Components/generic';

import type { TicketListTicket } from 'src/types/Ticket';

interface Props {
  taskType: TicketListTicket['taskType'];
}

const TicketTaskType: FC<Props> = ({ taskType }) => {
  return (
    <div style={styles.taskTypeContainer}>
      <Popup
        on={'hover'}
        hoverable={true}
        position="bottom right"
        content={<Label className={'ticket-list-item-ticket-type-label ticketType-' + taskType}>{taskType}</Label>}
        trigger={
          <Label className={'ticket-list-item-ticket-type-label TagSplitLength ticketType-' + taskType}>
            {taskType}
          </Label>
        }
      />
    </div>
  );
};

export default TicketTaskType;
