import * as React from 'react';

import DateTimePicker from '../DateTimePicker/DateTimePicker';

import type { SearchCriterion } from 'src/types/Search';

interface DateSelectorProps {
  value: any;
  textRight?: string;

  // value is the unix timestamp prefixed with TIMESTAMP, for Search.tsx
  onChange: (timestamp: string | undefined) => void;
}

export const timePrefix = 'TIMESTAMP';

const DateSelector = ({ value, textRight, onChange }: DateSelectorProps) => {
  const date = timestampPrefixToDate(value);

  return (
    <div>
      <DateTimePicker
        position="left center"
        isTimePicker={true}
        startDate={date}
        onChange={({ date }) => onChange(dateToTimestampPrefix(date))}
      />
      {textRight && <span style={{ marginLeft: '0.5rem' }}>{textRight}</span>}
    </div>
  );
};

export const criteriaToTimestamp = (criteria: SearchCriterion[], param: string) => {
  const criterion = criteria.find((criterion) => criterion.param === param)?.value;
  return dateToTimestampPrefix(criterion);
};

export const dateToTimestampPrefix = (date: string | number | null | undefined): string => {
  if (!date) {
    return '';
  }
  if (typeof date === 'number' || date == null || !date.startsWith(timePrefix)) {
    return `${timePrefix}${date}`;
  }

  return date;
};

export const timestampPrefixToDate = (timestamp: string | number | undefined): number => {
  if (timestamp == null || typeof timestamp === 'number') {
    timestamp = `TIMESTAMP${timestamp}`;
  }

  if (timestamp.startsWith(timePrefix)) {
    timestamp = timestamp.substring(timePrefix.length);
  }

  return Number(timestamp);
};

export default DateSelector;
