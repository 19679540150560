import { faHistory, faRefresh, faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iziToast from 'izitoast';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { FC } from 'react';

import ApiConfig from './api/ApiConfig';
import Button from './Components/generic/Button/Button';
import Modal from './Components/generic/Modal/Modal';

let interval: NodeJS.Timer;

const OutdatedVersionModal: FC = () => {
  const { t } = useTranslation();
  const updateAppHandler = () => window.location.reload();
  const postponeHandler = () => {
    ApiConfig.setMetaDataVersionPostpone(true);
    setOpen(false);
    iziToast.info({
      message: t('OUTDATED_VERSION_MODAL_TOAST'),
      timeout: 2500
    });
    interval = setInterval(checkVersion, 300000);
  };

  const checkVersion = async () => {
    await ApiConfig.fetchMetadata();
    if (!ApiConfig.isRunningCurrentVersion() && !ApiConfig.isVersionCheckPostponed) {
      setOpen(true);
      clearInterval(interval);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      checkVersion();
      interval = setInterval(checkVersion, 300000);
    }, 10000);

    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  const [isOpen, setOpen] = useState(false);

  return (
    <Modal
      open={isOpen}
      closeIcon
      header={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <FontAwesomeIcon icon={faTriangleExclamation} size="lg" style={{ marginRight: '15px' }} />
          {t('OUTDATED_VERSION_MODAL_TITLE')}
        </div>
      }
      footer={
        <>
          <Button type="error" iconLeft={<FontAwesomeIcon icon={faHistory} />} onClick={postponeHandler}>
            {t('OUTDATED_VERSION_MODAL_POSTPONE')}
          </Button>
          <Button type="primary" iconLeft={<FontAwesomeIcon icon={faRefresh} />} onClick={updateAppHandler}>
            {t('OUTDATED_VERSION_MODAL_UPDATE_NOW')}
          </Button>
        </>
      }
      onClose={postponeHandler}
    >
      {t('OUTDATED_VERSION_MODAL_DESCRIPTION')}
      <ul>
        <li>
          {t('OUTDATED_VERSION_MODAL_YOUR')}: <strong>{import.meta.env.VITE_CONTAINER_IMAGE}</strong>
        </li>
      </ul>
    </Modal>
  );
};

export default React.memo(OutdatedVersionModal);
